import React from 'react';
import './FindWorkSection.css';
import companyImg1 from '../assets/images/workstyles/findwork-image-01.jpeg';
import companyImg2 from '../assets/images/workstyles/findwork-image-02.jpeg';
import companyImg3 from '../assets/images/workstyles/findwork-image-03.jpeg';
import companyLogo1 from '../assets/images/workstyles/JAE-logo-2.png';
import companyLogo2 from '../assets/images/workstyles/teamz-logo.png';
import companyLogo3 from '../assets/images/workstyles/ai-communication-logo.JPG';
import companyLogo4 from '../assets/images/workstyles/selexia-logo.png';
import companyLogo5 from '../assets/images/workstyles/JAE-logo-2.png';
import companyLogo6 from '../assets/images/workstyles/teamz-logo.png';
import companyLogo7 from '../assets/images/workstyles/ai-communication-logo.JPG';
import companyLogo8 from '../assets/images/workstyles/selexia-logo.png';
// import companyLogo9 from '../assets/images/workstyles/e-valley-logo.svg';

const companyLogos = [
  companyLogo1, companyLogo2, companyLogo3, companyLogo4, companyLogo5, companyLogo6, companyLogo7, companyLogo8
];

const FindWorkSection = () => {
  return (
    <div className="find-work-section">
      <h2 className="find-work-title">Get a job</h2>
      <p className="find-work-description">様々な規模の企業に就職できます。</p>
      <div className="find-work-cards">
        <div className="find-work-card">
          <img src={companyImg1} alt="Company 1" />
          <h3>大企業</h3>
          <p>安定した雇用とリモートワーク</p>
        </div>
        <div className="find-work-card">
          <img src={companyImg2} alt="Company 2" />
          <h3>中小企業</h3>
          <p>新しいサービスを作るチャンス</p>
        </div>
        <div className="find-work-card">
          <img src={companyImg3} alt="Company 3" />
          <h3>ベンチャー企業</h3>
          <p>挑戦的なプロジェクトで成長</p>
        </div>
      </div>
      <p className='partner-title'>連携企業</p>
      {/* 横スクロールするロゴリスト */}
      <div className="find-work-logos">
        <div className="logos-wrapper">
          {companyLogos.concat(companyLogos).map((logo, index) => (
            <img key={index} src={logo} alt={`Partner ${index}`} className="partner-logo" />
          ))}
        </div>
      </div>


    </div>
  );
};

export default FindWorkSection;