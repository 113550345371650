import React from 'react';
import './Admission.css'; // CSS file
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CampaignSection from './CampaignSection';
import ProcedureSection from './ProcedureSection';
import AdmissionButtonSection from './AdmissionButtonSection';
import SchoolMeetingSection from './SchoolMeetingSection';
// import WebMeetingSection from './WebMeetingSection';
import TuitionSupportSection from './TuitionSupportSection';
// import RecommendationSection from './RecommendationSection';

import topImageFeature from '../assets/images/admission/admission-image-top-01.jpeg'; // Adjust the image path accordingly
import ContactButtonSection from './ContactButtonSection';

const Admission = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <div className="feature-page">
      <div className="page-title">
        <h1>入学案内</h1>
        <p>Admission</p>
      </div>
      <div className="learning-image-container">
        <img src={topImageFeature} alt="Admission" className="top-image" />
      </div>

      {/* <section id="campaign-section">
        <CampaignSection />
      </section> */}

      <section id="procedure-section">
        <ProcedureSection />
      </section>

      <section id="admission-button-section">
        <AdmissionButtonSection />
      </section>

      {/* <section id='school-meeting-section'>
        <SchoolMeetingSection />
      </section> */}

      {/* <section id='web-meeting-section'>
        <WebMeetingSection />
      </section> */}

      <section id='tuition-support-section'>
        <TuitionSupportSection />
      </section>

      {/* <section id='course-suggestion-section'>
        <RecommendationSection />
      </section> */}

      <section id='contact-button-section'>
        <ContactButtonSection />
      </section>
      
    </div>
  );
};

export default Admission;