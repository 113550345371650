import React from 'react';
import './TuitionSupportSection.css';
import companyLogo1 from '../assets/images/admission/tuition-support-image-01.png';
import companyLogo2 from '../assets/images/admission/tuition-support-image-02.png';

const tuitionSupportLinks = {
  crowdLoan: 'https://ac.crowdloan.jp/2326yb0bf6n70n33/cl/?bId=8d662tG0',
  schoolLoan: 'https://forms.gle/ZCVbDRcB7TXLSGiM8' // ここに学校独自ローンのURLを設定
};

const TuitionSupportSection = () => {
  const handleButtonClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="tuition-support-section">
      <h2 className="tuition-support-title">Tuition Support</h2>
      <p className="tuition-support-description">学費サポートがあります。</p>
      <div className="tuition-support-cards">
        <div className="tuition-support-card">
          <img src={companyLogo1} alt="Company 1" />
          <h3>クラウドローン</h3>
          <p>銀行教育ローン</p>
          <button onClick={() => handleButtonClick(tuitionSupportLinks.crowdLoan)}>
              シミュレーションへ
          </button>
        </div>

        {/* <div className="tuition-support-card">
          <img src={companyLogo2} alt="Company 1" />
          <h3>学校独自ローン</h3>
          <p>ご相談ください</p>
          <button onClick={() => handleButtonClick(tuitionSupportLinks.schoolLoan)}>
              お問い合わせ
          </button>
        </div> */}

      </div>
    </div>
  );
};

export default TuitionSupportSection;