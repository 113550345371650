import React from 'react';
import './WelcomeAboardSection.css';
// import { FaFilePdf } from 'react-icons/fa';

const WelcomeAboardSection = () => {

	return (
		<div className="welcome-aboard-section">
			<h2 className="welcome-aboard-title">Thank you</h2>
			<p className="welcome-aboard-description">私たちのビジョンに共感いただき、ありがとうございます。</p>
			<div className="welcome-aboard-cards">
				<h3 className="welcome-aboard-text">
					<p>ビジネスを通じた社会課題解決に本気で取り組んでいます。</p>
					<p>100年続く、経済圏を有するコミュニティを築きます。</p>
					<p>当校の魅力を、まずは知っていただけると嬉しいです。</p>
				</h3>
			</div>
		</div>
	);
};

export default WelcomeAboardSection;