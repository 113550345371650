// VisionSection.js

import React from "react";
import "./VisionSection.css"; // CSSファイルをインポート

// 必要な画像をインポート（例として仮の画像パスを使用）
import mentoringImage1 from "../assets/images/top/flow-image-01.png";
import mentoringImage2 from "../assets/images/top/flow-image-02.png";
import mentoringImage3 from "../assets/images/top/flow-image-03.png";
import mentoringImage4 from "../assets/images/top/flow-image-04.png";
import mentoringImage5 from "../assets/images/top/flow-image-05.png";

// メインブロックのデータ（subBlockの内容を含む5つの項目に更新）
const mainBlocks = [
  {
    id: 1,
    title: "目標設定",
    description: "まず初めに、目標を設定します。",
    image: mentoringImage1,
    subItems: [
      "どんな仕事に就きたいか", 
      "どんな人間になりたいか", 
      "どれくらいの期間で終了するか"
    ],
  },
  {
    id: 2,
    title: "科目選択",
    description: "目標を達成するために、適切な科目を選択します。",
    image: mentoringImage2,
    subItems: [
      "レベルに合わせた授業選択", 
      "目標に合わせた授業選択", 
      "複数のジャンルにまたがることも可能"
    ],
  },
  {
    id: 3,
    title: "動画授業",
    description: "自分の好きな時間で授業が受けられます。",
    image: mentoringImage3,
    subItems: ["好きな時間に", "好きな場所で", "何度でも"],
  },
  {
    id: 4,
    title: "進捗管理",
    description: "サポーターが進捗を管理し、モチベーションを保ちます。",
    image: mentoringImage4,
    subItems: [
      "サポーターによる進捗確認", 
      "課題によるスキルチェック", 
      "メンタルサポーターへの相談"
    ],
  },
  {
    id: 5,
    title: "技術サポート",
    description: "つまったら、講師にいつでも相談できます。",
    image: mentoringImage5,
    subItems: [
      "エンジニア講師によるサポート", 
      "チャットでいつでも相談", 
      "独学よりも成長スピードが早い"
    ],
  },
];

const VisionSection = () => {
  return (
    <div className="vision-section">
      <h2 className="vision-title">Vision</h2>
      <p>当校のビジョン</p>
      <div className="vision-container">
        <div className="main-vision">
          {mainBlocks.map((block) => (
            <div key={block.id} className="vision-block">
			  <div className="content-left">
			  	<h3>{block.title}</h3>
				<div className="sub-image">
					<img src={block.image} alt={block.title} />
				</div>
			  </div>
			  <div className="content-right">
			  <p>{block.description}</p>
				<ul>
					{block.subItems.map((item, idx) => (
					<li key={idx}>
						<i className="fa-solid fa-circle"></i> {item}
					</li>
					))}
				</ul>
			  </div>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VisionSection;