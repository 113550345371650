import React from 'react';
import './Member.css'; // CSS file
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import topImageFeature from '../assets/images/admission/admission-image-top-01.jpeg'; // Adjust the image path accordingly
import WelcomeAboardSection from './WelcomeAboardSection';
import VisionSection from './VisionSection';

const Member = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <div className="feature-page">
      <div className="page-title">
        <h1>応援者の方</h1>
        <p>Member</p>
      </div>
      <div className="learning-image-container">
        <img src={topImageFeature} alt="Member" className="top-image" />
      </div>

      <section id="welcome-aboard-section">
        <WelcomeAboardSection />   {/* Section for "Experience" */}
      </section>

      <section id="vision-section">
        <VisionSection />   {/* Section for "Experience" */}
      </section>



    </div>
  );
};

export default Member;